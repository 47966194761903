<template>
    <div class="menu-content">
        <main-nav-header></main-nav-header>
        <div class="content">
            <ul class="nav nav-tabs" role="tablist">
                <li v-for="(item,index) in tabs" class="nav-item" :key="index" role="presentation">
                    <a aria-controls="home" aria-selected="true" class="nav-link" :class="{'active': index == 0}" data-toggle="tab"
                       :href="'#basic'+ item" role="tab">{{item}}</a>
                </li>
            </ul>
            <div class="py-3">
                <div class="row row-cols-1 mb-4">
                    <div class="col">
                        <layout-box with-header with-dropdown>
                            <template #header>Header Title</template>
                            <template #dropdown>
                                <a class="dropdown-item" href="#">Action</a>
                                <a class="dropdown-item" href="#">Another action</a>
                                <a class="dropdown-item" href="#">Something else here</a>
                                <div class="dropdown-divider"></div>
                                <a class="dropdown-item" href="#">Separated link</a>
                            </template>
                        </layout-box>
                    </div>
                </div>

                <div class="row row-cols-2 mb-4">
                    <div class="col">
                        <layout-box with-header with-dropdown>
                            <template #header>Header Title</template>
                            <template #dropdown>
                                <a class="dropdown-item" href="#">Action</a>
                                <a class="dropdown-item" href="#">Another action</a>
                                <a class="dropdown-item" href="#">Something else here</a>
                                <div class="dropdown-divider"></div>
                                <a class="dropdown-item" href="#">Separated link</a>
                            </template>
                        </layout-box>
                    </div>
                    <div class="col">
                        <layout-box with-header with-dropdown>
                            <template #header>Header Title</template>
                            <template #dropdown>
                                <a class="dropdown-item" href="#">Action</a>
                                <a class="dropdown-item" href="#">Another action</a>
                                <a class="dropdown-item" href="#">Something else here</a>
                                <div class="dropdown-divider"></div>
                                <a class="dropdown-item" href="#">Separated link</a>
                            </template>
                        </layout-box>
                    </div>
                </div>

                <div class="row row-cols-3 mb-4">
                    <div class="col">
                        <layout-box with-header with-dropdown>
                            <template #header>Header Title</template>
                            <template #dropdown>
                                <a class="dropdown-item" href="#">Action</a>
                                <a class="dropdown-item" href="#">Another action</a>
                                <a class="dropdown-item" href="#">Something else here</a>
                                <div class="dropdown-divider"></div>
                                <a class="dropdown-item" href="#">Separated link</a>
                            </template>
                        </layout-box>
                    </div>
                    <div class="col">
                        <layout-box with-header with-dropdown>
                            <template #header>Header Title</template>
                            <template #dropdown>
                                <a class="dropdown-item" href="#">Action</a>
                                <a class="dropdown-item" href="#">Another action</a>
                                <a class="dropdown-item" href="#">Something else here</a>
                                <div class="dropdown-divider"></div>
                                <a class="dropdown-item" href="#">Separated link</a>
                            </template>
                        </layout-box>
                    </div>
                    <div class="col">
                        <layout-box with-header with-dropdown>
                            <template #header>Header Title</template>
                            <template #dropdown>
                                <a class="dropdown-item" href="#">Action</a>
                                <a class="dropdown-item" href="#">Another action</a>
                                <a class="dropdown-item" href="#">Something else here</a>
                                <div class="dropdown-divider"></div>
                                <a class="dropdown-item" href="#">Separated link</a>
                            </template>
                        </layout-box>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import LayoutBox from "../../../src/components/LayoutBox/LayoutBox";
    import MainNavHeader from "../../../src/components/MainNavHeader/MainNavHeader";

    export default {
        name: "Menu",
        components: {
            LayoutBox,
            MainNavHeader
        },
        data() {
            return {
                tabs: ['First', 'Second', 'Third', 'Fourth']
            }
        }
    }
</script>
