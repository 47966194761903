<template>
    <div class="header border-bottom">
        <h2 class="font-weight-normal">Job Display - Tabbed</h2>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="#">Page</a></li>
                <li class="breadcrumb-item"><a href="#">Page02</a></li>
                <li class="breadcrumb-item"><a href="#">Page03</a></li>
                <li aria-current="page" class="breadcrumb-item active">Page</li>
            </ol>
        </nav>

        <div class="ml-auto">
            <router-link to="/">
                <button class="btn btn-block btn-primary bg-custom-primary-dark">
                    Menu
                </button>
            </router-link>
            <span class="font-10 text-gray-400">Not part of design</span>
        </div>
    </div>
</template>

<script>
    export default {
        name: "MainNavHeader"
    }
</script>

