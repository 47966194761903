<template>
    <div class="card card-layout">
        <div v-if="withHeader" class="card-header">
            <slot name="header"></slot>

            <div class="dropdown-container px-1" data-toggle="dropdown">
                <fa icon="ellipsis-v" size="sm"></fa>
            </div>
            <div class="dropdown-menu">
                <slot name="dropdown">
                    <a class="dropdown-item" href="#">Action</a>
                    <a class="dropdown-item" href="#">Another action</a>
                    <a class="dropdown-item" href="#">Something else here</a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" href="#">Separated link</a>
                </slot>
            </div>

        </div>
        <div class="card-body">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: "LayoutBox",
        props: {
            withHeader: {
                type: Boolean,
                default: false
            },
            withDropdown: {
                type: Boolean,
                default: false
            }
        }
    }
</script>
